









































import { computed, defineComponent, ref } from '@vue/composition-api';
import JsonEditor from './JsonEditor.vue';
import SvgImage from './SvgImage.vue';
import { Scrollbar, Toggle } from '@/app/components';

export default defineComponent({
    name: 'ProcessedSampleView',
    components: {
        JsonEditor,
        SvgImage,
        Scrollbar,
        Toggle,
    },
    props: {
        processedSample: {
            type: Array,
            required: false,
        },
        previousProcessedSample: {
            type: Array,
            required: false,
        },
        size: {
            type: Number,
            default: 50,
        },
    },
    setup(props) {
        const showPreviousSample = ref<boolean>(false);

        const current = computed(() => (props.processedSample ? props.processedSample.slice(0, props.size) : null));
        const previous = computed(() =>
            props.previousProcessedSample ? props.previousProcessedSample.slice(0, props.size) : null,
        );

        return { showPreviousSample, current, previous };
    },
});
