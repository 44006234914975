import { computed, ref, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { ModelsAPI } from '../api';
import { DataType, HighLevelConceptFilters, Status, SuggestionStatus } from '../constants';
import { ModelConcept } from '../types';

export function useDataModel(model: Ref<any>) {
    const suggestions = ref<any>([]);
    const highlevelConcepts = ref<any>([]);
    const subConcepts = ref<any>([]);

    const isDraft = computed(() => model.value && model.value.status === Status.Draft);
    const isDeprecated = computed(() => model.value && model.value.status === Status.Deprecated);
    const isUnderRevision = computed(() => model.value && model.value.status === Status.UnderRevision);
    const isStable = computed(() => model.value && model.value.status === Status.Stable);
    const readOnly = computed(
        () => (model.value && model.value.majorVersion < model.value.domainMajorVersion) || isDeprecated.value,
    );

    /**
     * Defines the metadata object based on concept's datatype
     * @param datatype The datatype of the concept
     */
    const defineDatatypeMetadata = (datatype: DataType) => {
        const sameMeta = {
            encryption: true,
            sensitive: false,
            multiple: false,
            ordered: false,
            identifier: false,
            indexES: false,
            indexMongo: false,
        };

        switch (datatype) {
            case DataType.Integer:
            case DataType.Double:
                return {
                    ...sameMeta,
                    measurementType: null,
                    measurementUnit: null,
                };

            case DataType.String:
                return {
                    ...sameMeta,
                    spatialID: false,
                    spatial: false,
                };

            case DataType.DateTime:
            case DataType.Date:
            case DataType.Time:
                return {
                    ...sameMeta,
                    temporal: false,
                };

            case DataType.Boolean:
                return sameMeta;

            case DataType.Binary:
                return {
                    ...sameMeta,
                    encryption: false,
                };
            default:
                // for 'object' datatype
                return {
                    multiple: false,
                    ordered: false,
                    customizable: false,
                };
        }
    };

    /**
     * Deletes any unwanted metadata and adds non-existing ones
     * or replacing string "true" with boolean true
     * (fixes concept's metadata if necessary)
     * @param metadata The metadata of the concept
     * @param type The datatype of the concept
     */
    // #TODO: Theoretically this is not needed anymore - must ask
    const fixDatatypeMetadata = (metadata: any, type: DataType) => {
        const correctMetadata = defineDatatypeMetadata(type);
        const conceptMetadata = R.clone(metadata);

        const keys = Object.keys(conceptMetadata);
        keys.forEach((k: string) => {
            if (!(k in correctMetadata)) {
                delete conceptMetadata[k];
            } else if (conceptMetadata[k] === 'true') {
                conceptMetadata[k] = true;
            }
        });

        if (!conceptMetadata.multiple && conceptMetadata.ordered) {
            conceptMetadata.ordered = false;
        }
        /**  if a metadata exists in both objects, the value maintained in the
         *  merged object comes from the right-most object (e.g. conceptMetadata)
         */
        return { ...correctMetadata, ...conceptMetadata };
    };

    /**
     * Different message is displayed based on the high level concept filter
     * @param filter The filter chosen for high level concepts
     */
    const defineMessageBasedOnFilter = (filter: string) => {
        switch (filter) {
            case HighLevelConceptFilters.Proposed:
                return 'No Proposed High Level Concepts to be edited';
            case HighLevelConceptFilters.Active:
            case Status.Draft:
                return 'Add a High Level Concept to get started';
            case HighLevelConceptFilters.Deprecated:
                return 'No Deprecated High Level Concepts to be viewed';
            default:
                return 'Add a High Level Concept to get started';
        }
    };

    const filterInRelatedTerms = (concept: any, text: string) => {
        for (let i = 0; i < concept.relatedTerms?.length; i++) {
            if (R.toLower(concept.relatedTerms[i]).includes(R.toLower(text))) return true;
        }
        return false;
    };

    const filterByText = (concepts: any[], searchText?: string): any[] => {
        if (searchText) {
            return R.filter(
                (concept) =>
                    R.toLower(concept.name).includes(R.toLower(searchText)) ||
                    R.toLower(concept.description).includes(R.toLower(searchText)) ||
                    filterInRelatedTerms(concept, searchText),
                concepts,
            );
        }
        return concepts;
    };

    /**
     * Returns the filtered concepts
     * @param concepts High level concepts/ high level proposed concepts/ fields/ proposed fields
     * @param filter The filter chosen for high level concepts/ fields
     * @param fields Indications if we are filtering fields or high level concepts
     * @param searchText Name/ description/ related term of high level concept/ field
     */
    const filterConcepts = (concepts: any[], filter: string, fields: boolean, searchText?: string): any[] => {
        let filtered = [...concepts];
        if (filter === 'active') {
            filtered = R.filter((concept) => concept.status === Status.Stable, concepts);
        } else if (filter === 'in review') {
            filtered = R.filter((concept) => concept.status === Status.UnderRevision, concepts);
        } else if (filter === 'draft' || filter === 'new') {
            filtered = R.filter((concept) => concept.status === Status.Draft, concepts);
        } else if (filter === 'deprecated') {
            filtered = R.filter((concept) => concept.status === Status.Deprecated, concepts);
        } else if (filter === 'proposed') {
            filtered = R.filter((concept) => concept.status === SuggestionStatus.Pending, concepts);
        } else if (filter === 'simple' && fields) {
            filtered = R.filter((concept) => concept.type !== 'object', concepts);
        } else if (filter === 'related' && fields) {
            filtered = R.filter((concept) => concept.type === 'object', concepts);
        }

        filtered = filterByText(filtered, searchText);

        return filtered;
    };

    /**
     * Returns the proposed fields of a high level concept
     * @param selectedHighLevelConceptId The id of the parent of the proposed fields
     */
    const defineSelectedConceptSuggestions = (selectedHighLevelConceptId: any) =>
        R.filter((suggestion: any) => suggestion.parentId === selectedHighLevelConceptId, suggestions.value);

    /**
     * Returns either the filtered high level concepts or the high level proposed concepts
     * @param filter The filter chosen for the high level concepts
     */
    const defineFilteredHLConcepts = (filter: string) => {
        let filteredHLConcepts = [];
        if (filter === HighLevelConceptFilters.Proposed) {
            // Displayed in the Proposed HL concepts tab could be both Proposed HL Concepts + HL Concepts with Suggested fields

            if (isUnderRevision.value) {
                // for under revision data models, HL concepts which have just been deprecated are also displayed here if they have suggested fields
                filteredHLConcepts = suggestions.value.filter(
                    (f: any) =>
                        (f.parentId === null && f.status === SuggestionStatus.Pending) ||
                        f.status === Status.UnderRevision ||
                        (f.status === Status.Deprecated && !f.dateDeprecated),
                );
            } else {
                filteredHLConcepts = suggestions.value.filter(
                    (f: any) =>
                        (f.parentId === null && f.status === SuggestionStatus.Pending) || f.status === Status.Stable,
                );
            }
        } else {
            filteredHLConcepts = filterConcepts(highlevelConcepts.value, filter, false, undefined);
        }

        return filteredHLConcepts.sort((a: any, b: any) => {
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        });
    };

    /**
     * Returns the selected high level filter
     */
    const defineSelectedHighLevelFilter = () => {
        if (isDraft.value) return HighLevelConceptFilters.Draft;
        if (isDeprecated.value) return HighLevelConceptFilters.Deprecated;
        if (isUnderRevision.value) return HighLevelConceptFilters.All;
        return HighLevelConceptFilters.Active;
    };

    /**
     * Returns the unique saved changes
     * @param savedChanges Saved changes displayed in page
     */
    const defineSavedChanges = (savedChanges: any) => {
        const set: any = new Set();
        return savedChanges.filter((item: any) => {
            const subItem = JSON.stringify(item);

            if (!set.has(subItem)) {
                set.add(subItem);
                return true;
            }
            return false;
        }, set);
    };

    /**
     * Returns the filter options for high level concepts based on the model status
     */
    const defineFilterOptions = () => {
        if (isDraft.value || isDeprecated.value) return [];
        if (isUnderRevision.value)
            return [
                HighLevelConceptFilters.All,
                HighLevelConceptFilters.UnderRevision,
                HighLevelConceptFilters.New,
                HighLevelConceptFilters.Proposed,
                HighLevelConceptFilters.Deprecated,
            ];

        return [
            HighLevelConceptFilters.Active,
            HighLevelConceptFilters.Proposed,
            HighLevelConceptFilters.Deprecated,
            HighLevelConceptFilters.All,
        ];
    };

    /**
     * Retrieves the selected high level concept's fields and model's suggestions
     * @param highlevelConceptId The id of the selected high level concept
     * @param hlConceptFromAnotherModel Indicates whether the selected high level concept is a reference from another data model
     */
    const retrieveFields = (highlevelConceptId: number, hlConceptFromAnotherModel: boolean) => {
        return new Promise((resolve, reject) => {
            ModelsAPI.getConcepts(highlevelConceptId)
                .then((res: any) => {
                    subConcepts.value = res.data.sort((a: any, b: any) => {
                        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                    });
                    if (!hlConceptFromAnotherModel)
                        // retrieve data model suggestions only if the high level concept is not a referenced HL concept from another data model
                        ModelsAPI.getSuggestions(model.value.id).then((resSuggestions: any) => {
                            suggestions.value = defineSuggestions(resSuggestions.data);
                            resolve('success');
                        });
                    else resolve('success');
                })
                .catch((e) => {
                    const errorMessage = e ? e.message : 'Error while retrieving fields';
                    reject(new Error(errorMessage));
                });
        });
    };

    // suggested high level concepts and fields and fields' parents in order to be displayed as well
    const defineSuggestions = (suggestedConcepts: any) => {
        suggestedConcepts.forEach((suggestedConcept: any) => {
            if (suggestedConcept.referenceConceptId) {
                const relatedHLC = highlevelConcepts.value.find(
                    (hlc: ModelConcept) => hlc.id === suggestedConcept.referenceConceptId,
                );
                if (relatedHLC?.status === Status.Deprecated) suggestedConcept.readOnly = true;
            }
        });

        const fieldSuggestionsParentIds = R.filter((suggestion: any) => suggestion.parentId, suggestedConcepts).map(
            (s: any) => s.parentId,
        );

        // calculate number of suggested fields in stable high level concepts
        const noOfSuggestionsPerHLConcept = fieldSuggestionsParentIds.reduce((acc: any, param: number) => {
            if (!(param in acc)) {
                acc[param] = 1;
            } else {
                acc[param] += 1;
            }

            return acc;
        }, {});

        // add new field to high level concepts with the number of their suggestions
        const parentsOfFieldSuggestions = R.filter((hlc: any) => {
            if (hlc.id in noOfSuggestionsPerHLConcept) {
                hlc.noOfSuggestedFields = noOfSuggestionsPerHLConcept[hlc.id];
                return hlc;
            }
            return null;
        }, highlevelConcepts.value);

        return [...suggestedConcepts, ...parentsOfFieldSuggestions].sort((a: any, b: any) => {
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        });
    };

    return {
        defineMessageBasedOnFilter,
        defineDatatypeMetadata,
        fixDatatypeMetadata,
        filterConcepts,
        defineSelectedConceptSuggestions,
        defineFilteredHLConcepts,
        defineSelectedHighLevelFilter,
        defineSavedChanges,
        defineFilterOptions,
        retrieveFields,
        model,
        suggestions,
        highlevelConcepts,
        subConcepts,
        isDraft,
        isDeprecated,
        isUnderRevision,
        isStable,
        readOnly,
        defineSuggestions,
        filterByText,
    };
}
