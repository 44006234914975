import * as R from 'ramda';
import { ValidatorUtils } from './utils';

export const maxListLengthValidator = {
    message(field: string, args: any) {
        return `'${ValidatorUtils.prettifyName(field)}' must have at most ${args[0]} entries`;
    },
    validate(value: any, args: any) {
        const max = args[0];
        if (R.isNil(value) || R.isEmpty(value) || !R.is(Array, value) || value.length > max) {
            return false;
        }
        return true;
    },
};
