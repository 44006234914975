import { secured } from '@/app/config/axios';
import { CreateModelConcept, UpdateModelConcept } from '../types';

const endpoint = 'data-model';

export default {
    conceptNames: (id: number) => secured.get(`${endpoint}/domains/${id}/concept-names`),
    domainOfConcept: (id: number) => secured.get(`${endpoint}/concept/${id}/domain`),
    domains: () => secured.get(endpoint),
    all: () => secured.get(`${endpoint}/model-moderator/all`),
    getModel: (id: number) => secured.get(`${endpoint}/model-moderator/domains/${id}`),
    getConcepts: (id: number) => secured.get(`${endpoint}/model-moderator/domains/${id}/concepts`),
    getUsedStandards: (id: number) => secured.get(`${endpoint}/model-moderator/domains/${id}/used-standards`),
    metadata: (ids: number[]) => secured.post(`${endpoint}/metadata`, ids),
    publish: (id: number) => secured.put(`${endpoint}/concepts/${id}/publish`),
    delete: (id: number) => secured.delete(`${endpoint}/concepts/${id}`),
    createConcept: (data: any) => secured.post(`${endpoint}/concepts`, data),
    clone: (id: number) => secured.post(`${endpoint}/${id}/clone`),
    updateConcept: (id: number, data: UpdateModelConcept) => secured.put(`${endpoint}/concepts/${id}`, data),
    getSuggestions: (id: number) => secured.get(`${endpoint}/domains/${id}/suggestions`),
    createSuggestion: (data: any) => secured.post(`${endpoint}/suggestions`, data),
    approveSuggestion: (id: number, data: CreateModelConcept) =>
        secured.post(`${endpoint}/suggestions/${id}/approve`, data),
    rejectSuggestion: (id: number) => secured.put(`${endpoint}/suggestions/${id}/reject`),
    getDomainMajorVersion: (uid: string) => secured.get(`${endpoint}/domains/${uid}/major-version`),
    copyConcept: (data: any, partial: boolean) => secured.post(`${endpoint}/copy?partial=${partial}`, data),
    export: (id: number) => secured.get(`${endpoint}/${id}/export`),
    import: (data: any) => secured.post(`${endpoint}/import`, data),
    conceptTree: (id: number) => secured.get(`${endpoint}/domains/${id}`),
    domainLatestMapping: (id: number) => secured.get(`${endpoint}/domains/${id}/mapping-latest`),
};
