
















































































































































































































































































































































































































































































































import { Card, ConfirmButton, ConfirmModal, Scrollbar, TwButton } from '@/app/components';
import { S } from '@/app/utilities';
import {
    alphanumericValidator,
    maxLengthValidator,
    minLengthValidator,
    regexValidator,
    requiredValidator,
} from '@/app/validators';
import VueTagsInput from '@johmun/vue-tags-input';
import { ExclamationCircleIcon, PencilAltIcon, TrashIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { ModelsAPI } from '../api';
import { useDataModel } from '../composable';
import { HighLevelConceptFilters, Status, SuggestionStatus } from '../constants';
import ChangeIndication from './ChangeIndication.vue';
import Metadata from './Metadata.vue';
import StandardsMapping from './StandardsMapping.vue';
import TransferField from './TransferField.vue';

extend('required', requiredValidator);
extend('min', minLengthValidator);
extend('max', maxLengthValidator);
extend('alphanumeric', alphanumericValidator);
// giving a custom validator name in order to not be overriden by other 'regex' default validators
extend('concept_title_regex_validator', {
    ...regexValidator,
    message: 'Title must begin with a letter.',
});

export default defineComponent({
    name: 'EditConcept',
    props: {
        selectedItem: {
            type: Object,
            required: true,
        },
        model: {
            type: Object,
            required: true,
        },
        highLevelConcept: {
            type: Boolean,
            default: false,
        },
        collapsedLeftPane: {
            type: Boolean,
            required: true,
        },

        refreshSelection: {
            type: Boolean,
            default: false,
        },
        highlevelConcepts: {
            type: Array,
            default: () => [],
        },
        currentHighLevelFilter: {
            type: String,
            required: true,
        },
        changesToBeSaved: {
            type: Object,
            default: () => {},
        },
        savedChanges: {
            type: Object,
            default: () => {},
        },
        readOnlyFields: {
            type: Boolean,
            default: false,
        },
        loadingModel: {
            type: Boolean,
            default: false,
        },
        openStandardMappingFormToggle: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Card,
        ChangeIndication,
        ConfirmButton,
        ConfirmModal,
        Scrollbar,
        StandardsMapping,
        TwButton,
        ValidationProvider,
        ValidationObserver,
        VueTagsInput,
        TransferField,
        PencilAltIcon,
        TrashIcon,
        ExclamationCircleIcon,
        Metadata,
    },
    setup(props, { root, emit }: { root: any; emit: any }) {
        // UI variables
        const copiedModel = computed(() => props.model);
        const selectedConcept = ref<any>(null);
        const hovered = ref(false);
        const editPrefixDescription = ref<any>(null);
        let key = 0;
        const newRelatedConcept = ref({ prefix: null, description: null });
        const cancelPrefix = ref({ prefix: null, description: null });
        const addNewRelatedConcept = ref(false);
        const loading = ref(false);
        const relatedTermError = ref<string | null>(null);
        const tag = ref('');
        const showDeprecateModal = ref(false);
        const conceptRef = ref<any>(null);
        const initialMetadata = ref<any>(null);
        const isConceptDraft = ref<boolean>(false);
        const conceptStatus = ref<string>(Status.Stable);
        const validateReferencePrefix = ref<boolean>(false);
        const validateStandardsMapping = ref<boolean>(false);
        const standardsMapping = ref<any>();

        const calculatedChanges: any = computed(() => {
            if (props.changesToBeSaved && props.savedChanges) {
                const currentChanges = {};
                if (props.selectedItem.uid in props.savedChanges) {
                    props.savedChanges[props.selectedItem.uid].forEach(
                        (field: string) => (currentChanges[field] = false),
                    );
                }
                if (props.changesToBeSaved.unsaved) {
                    Object.keys(props.changesToBeSaved.unsaved).forEach((field: string) => {
                        currentChanges[field] = true;
                    });
                }
                return currentChanges;
            }

            return null;
        });

        const { isDraft, isUnderRevision, isStable, readOnly: readOnlyModel } = useDataModel(copiedModel);

        const isProposal = computed(() => props.selectedItem.status === SuggestionStatus.Pending);
        const disableEditing = computed(
            () =>
                props.selectedItem.status === Status.Deprecated ||
                ((props.selectedItem.status === Status.Stable || props.selectedItem.status === Status.UnderRevision) &&
                    props.currentHighLevelFilter === HighLevelConceptFilters.Proposed) ||
                (isProposal.value && !isStable.value),
        );
        const readOnly = computed(() => readOnlyModel.value || disableEditing.value || props.readOnlyFields);

        const selectedItemChanged = (args: any) => {
            selectedConcept.value = R.clone({ ...args }['0']);
            validateReferencePrefix.value = false;
            if (isProposal.value && selectedConcept.value.type === 'object' && !props.highLevelConcept) {
                selectedConcept.value.referencePrefix = [];
                addNewRelatedConcept.value = true;
            } else if (
                selectedConcept.value.referencePrefix &&
                selectedConcept.value.type === 'object' &&
                !props.highLevelConcept
            ) {
                validateReferencePrefix.value = !!selectedConcept.value.referencePrefix.length;
                addNewRelatedConcept.value = false;
            } else {
                validateReferencePrefix.value = true;
                addNewRelatedConcept.value = false;
            }
            validateStandardsMapping.value = true;

            initialMetadata.value = isProposal.value
                ? { sensitive: true, multiple: false, ordered: true }
                : R.clone(selectedConcept.value.metadata);
            editPrefixDescription.value = null;
            newRelatedConcept.value = { prefix: null, description: null };
            cancelPrefix.value = { prefix: null, description: null };
            tag.value = '';
            relatedTermError.value = '';
            showDeprecateModal.value = false;
            isConceptDraft.value = isUnderRevision.value && selectedConcept.value.status === Status.Draft;
            conceptStatus.value = selectedConcept.value.status;
            if (conceptRef.value !== null) {
                conceptRef.value.reset();
            }
        };

        watch(
            () => [props.selectedItem, props.refreshSelection],
            (args) => {
                selectedItemChanged(args);
            },
        );
        selectedItemChanged([props.selectedItem, props.refreshSelection]);

        const changeTags = (newTags: any) => {
            relatedTermError.value = '';
            if (selectedConcept.value) {
                selectedConcept.value.relatedTerms = newTags.map((t: any) => t.text); // eslint-disable-line no-param-reassign
                emit('change', 'relatedTerms', selectedConcept.value.relatedTerms);
            }
        };

        const tags = computed(() => {
            const tmpArray = [] as any;
            if (selectedConcept.value && selectedConcept.value.relatedTerms) {
                selectedConcept.value.relatedTerms.forEach((r: any) => tmpArray.push({ text: r }));
            }
            relatedTermError.value = !tag.value ? '' : relatedTermError.value;

            return tmpArray;
        });

        const validateRelatedTerm = () => {
            if (tag.value.trim()) {
                if (tag.value.length === 50) {
                    relatedTermError.value = 'Maximum number of characters allowed for Related term reached';
                    return false; // returning false here because the tag addition is not allowed by the prop "maxlength", but we want to display this message at exact that number of character
                }
                if (!relatedTermError.value || tag.value.length < 50) {
                    const lowerCaseRelatedTerms = selectedConcept.value.relatedTerms.map((rt: string) =>
                        rt.toLowerCase(),
                    );
                    if (lowerCaseRelatedTerms.includes(tag.value.toLowerCase()))
                        relatedTermError.value = `Related term '${tag.value}' already exists`;
                    else relatedTermError.value = '';
                }

                return !!relatedTermError.value;
            }
            return false;
        };

        const showModal = () => {
            showDeprecateModal.value = true;
        };

        const deprecate = () => {
            showDeprecateModal.value = false;
            if (selectedConcept.value) {
                loading.value = true;
                if (!isDraft.value && !isUnderRevision.value && props.highLevelConcept) {
                    emit('deprecation-loading', true);
                }
                ModelsAPI.delete(selectedConcept.value.id)
                    .then((res) => {
                        (root as any).$toastr.s(
                            `${props.highLevelConcept ? 'Concept' : 'Field'} '${S.sanitizeHtml(
                                selectedConcept.value.name,
                            )}' is now ${selectedConcept.value.status === Status.Draft ? 'deleted' : 'deprecated'}`,
                            `${selectedConcept.value.status === Status.Draft ? 'Deleted' : 'Deprecated'}`,
                        );
                        emit('deprecate-concept', isUnderRevision.value ? null : res.data.id);
                    })
                    .catch(() => {
                        (root as any).$toastr.e(
                            `${props.highLevelConcept ? 'Concept' : 'Field'} '${S.sanitizeHtml(
                                selectedConcept.value.name,
                            )}' failed to be ${
                                selectedConcept.value.status === Status.Draft ? 'deleted' : 'deprecated'
                            }`,
                            `${selectedConcept.value.status === Status.Draft ? 'Deleted' : 'Deprecated'}`,
                        );
                    })
                    .then(() => {
                        loading.value = false;
                        if (!isDraft.value && !isUnderRevision.value && props.highLevelConcept) {
                            emit('deprecation-loading', false);
                        }
                    });
            }
        };

        const updateStandardsMapping = (updatedMappings: any) => {
            selectedConcept.value.standardsMapping = updatedMappings; // eslint-disable-line no-param-reassign
            emit('change', 'standardsMapping', selectedConcept.value.standardsMapping);
        };

        const validateInitialStandardsMapping = computed(() => {
            if (selectedConcept.value.standardsMapping && selectedConcept.value.standardsMapping.length) {
                const issue = selectedConcept.value.standardsMapping.find(
                    (sm: any) => R.isNil(sm.name) || R.isNil(sm.standard) || R.isNil(sm.version) || R.isNil(sm.type),
                );

                if (issue) {
                    return false;
                }
            }

            return true;
        });

        const addRelatedConcept = (relatedConcepts: any) => {
            relatedConcepts.push(newRelatedConcept.value);
            validateReferencePrefix.value = !!relatedConcepts.length;
            newRelatedConcept.value = { prefix: null, description: null };
            addNewRelatedConcept.value = false;
            emit('change', 'referencePrefix', relatedConcepts);
        };

        const removeRelatedConcept = (index: number) => {
            selectedConcept.value.referencePrefix.splice(index, 1);
            hovered.value = false;
            emit('change', 'referencePrefix', selectedConcept.value.referencePrefix);
        };

        const componentKey = computed(() => {
            key += 1;
            return selectedConcept.value ? key : null;
        });

        const updateMetadata = (metadata: any) => {
            if (metadata) {
                selectedConcept.value.metadata = metadata; // eslint-disable-line no-param-reassign
                emit('change', 'metadata', selectedConcept.value.metadata);
            }
        };

        const updateConcept = async () => {
            const valid = await conceptRef.value.validate();
            if (valid && selectedConcept.value) {
                const updatedRelatedTerms = [] as any;
                tags.value.forEach((r: any) => updatedRelatedTerms.push(r.text));

                const payload = {
                    ...selectedConcept.value,
                    name: selectedConcept.value.name.trim(),
                    relatedTerms: updatedRelatedTerms.length ? updatedRelatedTerms : null,
                    standardsMapping:
                        selectedConcept.value.standardsMapping && selectedConcept.value.standardsMapping.length
                            ? selectedConcept.value.standardsMapping
                            : null,
                };

                loading.value = true;
                emit('loading');
                ModelsAPI.updateConcept(selectedConcept.value.id, payload)
                    .then((res: any) => {
                        initialMetadata.value = R.clone(selectedConcept.value.metadata);

                        emit('update-concept', res.data);
                        (root as any).$toastr.s(
                            `${props.highLevelConcept ? 'Concept' : 'Field'} '${S.sanitizeHtml(
                                payload.name,
                            )}' is updated successfully`,
                            'Success',
                        );
                    })
                    .catch((e) => {
                        (root as any).$toastr.e(
                            `${props.highLevelConcept ? 'Concept' : 'Field'} '${S.sanitizeHtml(
                                payload.name,
                            )}' failed to be updated: ${e.response.data.message}`,
                            'Error',
                        );
                    })
                    .finally(() => {
                        loading.value = false;
                        emit('loading');
                    });
            }
        };

        const rejectProposed = () => {
            loading.value = true;
            emit('loading');
            ModelsAPI.rejectSuggestion(selectedConcept.value.id)
                .then(() => {
                    (root as any).$toastr.s(
                        `Proposed ${props.highLevelConcept ? 'Concept' : 'Field'} '${S.sanitizeHtml(
                            selectedConcept.value.name,
                        )}' is rejected successfully`,
                        'Success',
                    );
                    emit('reject-proposed');
                })
                .catch(() => {
                    (root as any).$toastr.e(
                        `Proposed ${props.highLevelConcept ? 'Concept' : 'Field'} '${S.sanitizeHtml(
                            selectedConcept.value.name,
                        )}' failed to be rejected`,
                        'Error',
                    );
                })
                .then(() => {
                    loading.value = false;
                    emit('loading');
                });
        };

        const approveProposed = () => {
            selectedConcept.value.parentId = selectedConcept.value.parentId
                ? selectedConcept.value.parentId
                : selectedConcept.value.domainId;
            selectedConcept.value.domainUid = props.model.uid;
            emit('create-proposed', selectedConcept.value);
        };

        const showStandardsMapping = computed(() => {
            return (
                !readOnly.value ||
                (readOnly.value &&
                    selectedConcept.value.standardsMapping &&
                    selectedConcept.value.standardsMapping.length)
            );
        });

        const showRelatedConcept = computed(() => {
            return (
                (selectedConcept.value.type === 'object' && !props.highLevelConcept && !readOnly.value) ||
                (readOnly.value &&
                    selectedConcept.value.referencePrefix &&
                    selectedConcept.value.referencePrefix.length)
            );
        });

        const changeRelatedConceptActionsLayout = computed(() => {
            return (
                selectedConcept.value.referencePrefix &&
                selectedConcept.value.referencePrefix.length > 1 &&
                (isProposal.value || isDraft.value || isConceptDraft.value)
            );
        });

        const openEditRelatedConcept = (index: number, referencePrefix: any) => {
            editPrefixDescription.value = index;
            cancelPrefix.value.prefix = referencePrefix.prefix;
            cancelPrefix.value.description = referencePrefix.description;
        };

        const cancelAddNewRelatedConcept = () => {
            addNewRelatedConcept.value = false;
            newRelatedConcept.value.prefix = null;
            newRelatedConcept.value.description = null;
        };

        const hasChanges = computed(
            () => !!Object.keys(calculatedChanges.value).find((field: string) => calculatedChanges.value[field]),
        );

        const disableApproveProposalOrUpdate = computed(() => {
            return (
                loading.value ||
                props.loadingModel ||
                !validateReferencePrefix.value ||
                !validateStandardsMapping.value ||
                !validateInitialStandardsMapping.value
            );
        });

        const placeholderText = computed(() => {
            return {
                name: `Enter ${props.highLevelConcept ? 'concept' : 'field'} title`,
                description: `Enter a description for the ${props.highLevelConcept ? 'concept' : 'field'}`,
            };
        });

        const disableAddRelatedConceptBtn = computed(
            () =>
                loading.value ||
                !newRelatedConcept.value.prefix ||
                !(newRelatedConcept.value.prefix as string).match(/^[a-z0-9]+$/i) ||
                !newRelatedConcept.value.description,
        );

        const closeStandardsMappingForm = () => {
            if (standardsMapping.value) standardsMapping.value.closeStandardMappingForm();
        };

        return {
            addNewRelatedConcept,
            addRelatedConcept,
            approveProposed,
            cancelAddNewRelatedConcept,
            cancelPrefix,
            changeTags,
            componentKey,
            conceptRef,
            conceptStatus,
            changeRelatedConceptActionsLayout,
            deprecate,
            disableApproveProposalOrUpdate,
            editPrefixDescription,
            emit,
            hovered,
            initialMetadata,
            isConceptDraft,
            loading,
            newRelatedConcept,
            openEditRelatedConcept,
            placeholderText,
            rejectProposed,
            relatedTermError,
            removeRelatedConcept,
            selectedConcept,
            showDeprecateModal,
            showModal,
            showRelatedConcept,
            showStandardsMapping,
            tag,
            tags,
            updateConcept,
            updateMetadata,
            updateStandardsMapping,
            validateInitialStandardsMapping,
            validateReferencePrefix,
            validateStandardsMapping,
            disableAddRelatedConceptBtn,
            readOnly,
            isDraft,
            isUnderRevision,
            isProposal,
            copiedModel,
            hasChanges,
            calculatedChanges,
            validateRelatedTerm,
            standardsMapping,
            closeStandardsMappingForm,
        };
    },
});
