








































import store from '@/app/store';
import { ExecutionError } from '@/app/store/execution-errors';
import { AlertSourceType } from '@/modules/alert/constants/alert.constants';
import { notificationDescription } from '@/modules/notification/config/messages.templates';
import { computed, defineComponent } from '@vue/composition-api';
import { TimeAgo } from 'vue2-timeago';
import {
    NotificationAdministratorIcon,
    NotificationDataCheckinJobIcon,
    NotificationDigestIcon,
    NotificationExceptionEventIcon,
    NotificationRetrievalQueryIcon,
    NotificationSuggestionIcon,
    NotificationWorkflowIcon,
    NotificationYellowAlertIcon,
    NotificationRedAlertIcon,
} from './icons';

export default defineComponent({
    name: 'NotificationListItem',
    components: { TimeAgo, NotificationDataCheckinJobIcon, NotificationWorkflowIcon, NotificationRetrievalQueryIcon },
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    setup(props, { root, emit }: { root: any; emit: any }) {
        const executionErrors = computed(() => store.state.executionErrors.executionErrors);
        const markAsSeen = async (id: number) => {
            try {
                await store.dispatch.notificationEngine.changeSeenAt(id);
            } catch (e: any) {
                root.$toastr.e(`Notification could not marked as seen: ${e.message}`, 'Error');
            } finally {
                emit('closeDropdown');
            }
        };

        const hasDescription = (notification: any) => {
            if (notification.payload.errorCode) {
                const executionError = executionErrors.value.find(
                    (err: ExecutionError) => err.code === notification.payload.errorCode,
                );
                return executionError?.title || `Unknown Error Code: ${notification.payload.errorCode}`;
            }
            return notificationDescription(notification);
        };

        const hasErrorCode = computed(() => {
            if (props.notification.payload.errorCode) {
                const executionError = executionErrors.value.find(
                    (err: ExecutionError) => err.code === props.notification.payload.errorCode,
                );
                if (props.notification.payload.referenceTitle)
                    return `${props.notification.payload.referenceTitle}: ${
                        executionError?.title || `Unknown Error Code: ${props.notification.payload.errorCode}`
                    }`;
                return executionError?.title || `Unknown Error Code: ${props.notification.payload.errorCode}`;
            }
            return notificationDescription(props.notification);
        });

        const createRedirectionConfiguration = computed(() => {
            let payload = null;
            switch (props.notification.payload.referenceType) {
                case 'data-checkin':
                    if (['dcj.asset.deleted'].includes(props.notification.eventType)) {
                        payload = {
                            name: 'data-checkin-jobs',
                            params: {
                                id: props.notification.payload.referenceId,
                            },
                        };
                    } else {
                        payload = {
                            name: 'data-checkin-jobs:history',
                            params: {
                                id: props.notification.payload.referenceId,
                                status: props.notification.eventType,
                                type: props.notification.payload.referenceType,
                            },
                            query: {
                                executionId: props.notification.payload.executionId || undefined,
                            },
                        };
                    }

                    break;
                case 'analytics':
                    if (
                        [
                            'workflow.usedBlockDisabled',
                            'workflow.triggerConditionLostAccess',
                            'workflow.asset.deleted',
                        ].includes(props.notification.eventType)
                    ) {
                        payload = {
                            name: 'workflow-designer:edit',
                            params: {
                                id: props.notification.payload.referenceId,
                            },
                        };
                    } else {
                        payload = {
                            name: 'history',
                            params: {
                                id: props.notification.payload.executionId,
                                workflowId: props.notification.payload.referenceId,
                                status: props.notification.eventType,
                                type: props.notification.payload.referenceType,
                            },
                            query: {
                                executionId: props.notification.payload.executionId || undefined,
                            },
                        };
                    }
                    break;
                case 'ModelSuggestion':
                case 'ModelConcept':
                    if (props.notification.eventType == 'concept.created') {
                        payload = {
                            name: 'model-manager:edit',
                            params: {
                                id: props.notification.payload.referenceId,
                                suggestionId: props.notification.payload.suggestionId,
                                parentConceptName: props.notification.payload.parentConceptName,
                                modelName: props.notification.payload.referenceTitle,
                            },
                        };
                    } else if (props.notification.eventType == 'concept.deleted') {
                        payload = {
                            name: 'model-manager:edit',
                            params: {
                                id: props.notification.payload.parentConceptId,
                                referenceConceptId: props.notification.payload.referenceId,
                                action: props.notification.payload.action,
                            },
                        };
                    } else {
                        payload = {};
                    }
                    break;
                case 'ExceptionEvent':
                    if (props.notification.eventType == 'exceptionEvent.unprocessed') {
                        payload = {
                            name: 'admin-portal',
                            query: {
                                view: 'ExceptionEvents',
                            },
                        };
                    } else {
                        payload = {};
                    }
                    break;
                case 'Administration':
                    payload = {};
                    break;
                case 'RetrievalQuery':
                    payload = {
                        name: 'retrieval:configure',
                        params: {
                            id: props.notification.payload.referenceId,
                            status: props.notification.eventType,
                            type: props.notification.payload.referenceType,
                        },
                    };
                    break;
                case 'AlertEvent':
                    if (
                        [AlertSourceType.Dataset, AlertSourceType.Result].includes(
                            props.notification.payload.sourceType,
                        )
                    ) {
                        payload = {
                            name: 'assets:view',
                            params: {
                                id: props.notification.payload.entityId,
                            },
                        };
                    }
                    if (props.notification.payload.sourceType === AlertSourceType.DataCheckin) {
                        payload = {
                            name: 'data-checkin-jobs:history',
                            params: {
                                id: props.notification.payload.entityId,
                            },
                            query: {
                                executionId: props.notification.payload.executionId || undefined,
                            },
                        };
                    }
                    if (props.notification.payload.sourceType === AlertSourceType.Analytics) {
                        payload = {
                            name: 'history',
                            params: {
                                workflowId: props.notification.payload.entityId,
                            },
                            query: {
                                executionId: props.notification.payload.executionId || undefined,
                            },
                        };
                    }

                    break;
                case 'daily_digest':
                case 'weekly_digest':
                    payload = {
                        name: 'notifications-digest',
                        params: {
                            notificationGroups: props.notification.payload,
                            createdAt: props.notification.createdAt,
                            digestType: props.notification.eventType,
                            id: props.notification.id,
                        },
                    };
                    break;
                default:
                //
            }

            return payload;
        });

        const notificationType = (type: string) => {
            switch (type) {
                case 'data-checkin':
                    return NotificationDataCheckinJobIcon;
                case 'analytics':
                    return NotificationWorkflowIcon;
                case 'ModelSuggestion':
                case 'ModelConcept':
                    return NotificationSuggestionIcon;
                case 'ExceptionEvent':
                    return NotificationExceptionEventIcon;
                case 'Administration':
                    return NotificationAdministratorIcon;
                case 'RetrievalQuery':
                    return NotificationRetrievalQueryIcon;
                case 'AlertEvent':
                    return props.notification.payload.criticality === 'yellow'
                        ? NotificationYellowAlertIcon
                        : NotificationRedAlertIcon;
                case 'daily_digest':
                case 'weekly_digest':
                    return NotificationDigestIcon;
                default:
                    return null;
            }
        };

        return {
            notificationDescription,
            markAsSeen,
            hasDescription,
            hasErrorCode,
            createRedirectionConfiguration,
            notificationType,
        };
    },
});
