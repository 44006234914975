













import { PropType, computed, defineComponent, Ref, ref, watch } from '@vue/composition-api';
import { invertObj, isNil } from 'ramda';
import apexchart from 'vue-apexcharts';
import { Asset } from '../types';
import { XCircleIcon, LightBulbIcon } from '@vue-hero-icons/solid';
import { AssetType, AssetTypeId } from '../constants';
import { useAxios } from '@/app/composable';
import { MetricsAPI } from '../api';
import { SelfBuildingSquareSpinner } from 'epic-spinners';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ApexOptions } from 'apexcharts';

import { AssetMetricsQuery } from '../types/asset-metrics-query.type';
import { Component } from 'vue/types/options';
import GraphError from './GraphError.vue';
import { useGraphUtils } from '../composable/graph-utils';

dayjs.extend(utc);

export default defineComponent({
    name: 'CompletenessGraph',
    props: {
        asset: { type: Object as PropType<Asset>, required: true },
        query: { type: Object as PropType<AssetMetricsQuery>, required: true },
        height: { type: String, default: '350px' },
    },
    components: { SelfBuildingSquareSpinner, apexchart, GraphError },
    setup(props) {
        const { exec, loading } = useAxios(true);
        const { formatEpochBy } = useGraphUtils();
        const error: Ref<{ message: string; tooltip: string; icon: Component; classes: string } | undefined> = ref();

        const assetType: AssetType = invertObj(AssetTypeId)[props.asset.assetTypeId] as AssetType;

        const series: Ref<
            {
                name: string;
                data: { x: string; y: number; noMeasurement: boolean }[];
                type: string;
                color?: string;
                labels?: any;
            }[]
        > = ref([]);

        const options: Ref<ApexOptions> = computed(() => {
            if (assetType === AssetType.Dataset)
                return {
                    chart: {
                        zoom: { enabled: false },
                        animations: { enabled: false },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    yaxis: {
                        title: {
                            text: `Completeness and Quality Improvements (%)`,
                        },
                        min: 0,
                        max: 100,
                        labels: {
                            formatter: (val: number, settings: { dataPointIndex: number; seriesIndex: number }) => {
                                if (isNil(settings.dataPointIndex) || isNil(settings.seriesIndex))
                                    return `${formatDecimals(val)}%`;
                                if (series.value.length > settings.seriesIndex)
                                    return series.value[settings.seriesIndex].data[settings.dataPointIndex]
                                        ?.noMeasurement
                                        ? `No measurement`
                                        : `${formatDecimals(val)}%`;
                                return `${formatDecimals(val)}%`;
                            },
                        },
                        tickAmount: 5,
                    },
                    stroke: {
                        curve: 'smooth',
                        width: [0, 0, 4],
                    },
                    xaxis: {
                        tickAmount: 'dataPoints',
                        type: 'category',
                        labels: {
                            rotate: -45,
                            formatter: (val: string) => {
                                return formatEpochBy(val, props.query.granularity, true);
                            },
                            style: {
                                fontSize: '10.5px',
                            },
                        },
                        title: { text: 'Time' },
                    },
                    legend: {
                        position: 'top',
                    },
                };
            else
                return {
                    chart: {
                        stacked: false,
                        zoom: { enabled: false },
                        events: {
                            legendClick(chartContext: any, seriesIndex: any, config: any) {
                                if (config.config.series[seriesIndex].name === 'Total Result Completeness') {
                                    const dataCompletenessPerAnalyticsIndex = config.config.series.findIndex(
                                        (serie: any) =>
                                            serie.name === 'Data Completeness per Analytics Pipeline Execution',
                                    );
                                    // series is being hidden
                                    config.config.yaxis[dataCompletenessPerAnalyticsIndex].show = !!config.config
                                        .series[seriesIndex].data.length;
                                }
                            },
                        },
                        animations: { enabled: false },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: 'smooth',
                        width: [0, 0, 4],
                    },
                    xaxis: {
                        tickAmount: 'dataPoints',
                        type: 'category',
                        labels: {
                            rotate: -45,
                            formatter: (val: string) => {
                                return formatEpochBy(val, props.query.granularity, true);
                            },
                            style: {
                                fontSize: '10.5px',
                            },
                        },
                        title: {
                            text: 'Time',
                        },
                    },
                    yaxis: [
                        {
                            title: {
                                text: `Completeness (%)`,
                            },
                            seriesName: `Completeness (%)`,
                            min: 0,
                            max: 100,
                            labels: {
                                formatter: (
                                    val: number,
                                    settings: { dataPointIndex: number; seriesIndex: number },
                                ): string => {
                                    if (series.value.length > settings.seriesIndex)
                                        return series.value[settings.seriesIndex].data[settings.dataPointIndex]
                                            ?.noMeasurement
                                            ? `No measurement`
                                            : `${formatDecimals(val)}%`;
                                    return `${formatDecimals(val)}%`;
                                },
                            },
                            tickAmount: 5,
                        },
                        {
                            title: {
                                text: `Completeness (%)`,
                            },
                            seriesName: `Completeness (%)`,
                            show: false,
                            labels: {
                                formatter: (val: number, settings: { dataPointIndex: number; seriesIndex: number }) => {
                                    if (series.value.length > settings.seriesIndex)
                                        return !isNil(
                                            series.value[settings.seriesIndex].data[settings.dataPointIndex],
                                        ) &&
                                            series.value[settings.seriesIndex].data[settings.dataPointIndex]
                                                .noMeasurement
                                            ? `No measurement`
                                            : `${formatDecimals(val)}%`;
                                    return `${formatDecimals(val)}%`;
                                },
                            },
                            tickAmount: 5,
                            min: 0,
                            max: 100,
                        },
                        {
                            opposite: true,
                            seriesName: 'Column',
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: true,
                            },
                            title: {
                                text: 'Volume (records)',
                            },
                            labels: {
                                formatter: (val: number) => (!isNil(val) ? `${formatDecimals(val)}` : `No measurement`),
                            },
                        },
                    ],
                    legend: {
                        position: 'top',
                    },
                };
        });

        const refetch = () => {
            error.value = undefined;
            const secondaryRequest =
                assetType === AssetType.Dataset
                    ? MetricsAPI.getDatasetQualityImprovements(props.asset.id, props.query)
                    : MetricsAPI.getResultVolume(props.asset.id, props.query);

            const secondaryMetricTitle = assetType === AssetType.Dataset ? 'Quality Improvements' : 'Volume';
            const secondaryMetricKey = assetType === AssetType.Dataset ? 'totalQualityImprovement' : 'volume';
            Promise.all([
                exec(MetricsAPI.getCompleteness(assetType, props.asset.id, props.query)),
                exec(secondaryRequest),
            ])
                .then(([completenessResponse, secondaryResponse]: any[]) => {
                    const completeness = [];
                    const completenessPerExecution = [];
                    const secondary: any[] = [];

                    for (let r = 0; r < completenessResponse.data.results.length; r++) {
                        const {
                            totalCompleteness,
                            executionCompleteness,
                            timestamp,
                        } = completenessResponse.data.results[r];
                        completeness.push({
                            x: timestamp,
                            y: isNil(totalCompleteness) ? 0 : totalCompleteness * 100,
                            noMeasurement: isNil(totalCompleteness),
                        });
                        completenessPerExecution.push({
                            x: timestamp,
                            y: isNil(executionCompleteness) ? 0 : executionCompleteness * 100,
                            noMeasurement: isNil(executionCompleteness),
                        });
                    }
                    for (let r = 0; r < secondaryResponse.data.results.length; r++) {
                        const secondaryResult = secondaryResponse.data.results[r];
                        let y = secondaryResult[secondaryMetricKey];
                        if (secondaryMetricKey !== 'volume')
                            y = isNil(secondaryResult[secondaryMetricKey])
                                ? 0
                                : secondaryResult[secondaryMetricKey] * 100;

                        secondary.push({
                            x: secondaryResult.timestamp,
                            y,
                            noMeasurement: isNil(secondaryResult[secondaryMetricKey]),
                        });
                    }
                    series.value = [
                        {
                            name: `Total ${assetType === AssetType.Dataset ? 'Dataset' : 'Result'} Completeness`,
                            data: completeness,
                            type: 'bar',
                            color: '#6c9dc3',
                        },
                        {
                            name: `Data Completeness per ${
                                assetType === AssetType.Dataset ? 'Data Check-in' : 'Analytics'
                            } Pipeline Execution`,
                            data: completenessPerExecution,
                            type: 'bar',
                            color: '#e3a242',
                        },
                        {
                            name: secondaryMetricTitle,
                            data: secondary,
                            type: 'line',
                            color: '#6da78b',
                        },
                    ];
                })
                .catch((e: any) => {
                    if (e?.request?.status === 413)
                        error.value = {
                            message: `Too many data points found with given time period and granularity`,
                            tooltip: `Change selected time period and granularity to reduce number of data points`,
                            icon: LightBulbIcon,
                            classes: 'text-warn-700',
                        };
                    else
                        error.value = {
                            message: `Unable to fetch Completeness &
                ${assetType === AssetType.Dataset ? 'Quality Improvements' : 'Volume'} visualisation at this
                time...`,
                            tooltip: e?.response?.data?.message,
                            icon: XCircleIcon,
                            classes: `text-danger-700`,
                        };
                });
        };

        const formatDecimals = (value: number) => {
            return parseFloat(Number(value).toFixed(2));
        };

        watch(
            () => props.query,
            () => refetch(),
            { immediate: true, deep: true },
        );

        return {
            series,
            options,
            error,
            loading,
            AssetType,
            assetType,
            formatDecimals,
        };
    },
});
