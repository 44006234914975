


























import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import vue2Dropzone from 'vue2-dropzone';

export default defineComponent({
    name: 'FileDropzone',
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        hasPreviewTemplate: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        uploadedFiles: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
    },
    components: {
        vueDropzone: vue2Dropzone,
    },
    setup(props, { emit }) {
        const vuedropzone = ref<any>();

        const filesText = computed(() => {
            return props.multiple ? 'files' : 'a file';
        });

        const template = () => `<div class="flex items-center px-0 py-2 space-x-2 text-sm text-left ${
            !props.hasPreviewTemplate ? 'hidden' : ''
        }">
                <div class="flex items-center flex-grow space-x-1">
                    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path>
                    </svg>
                    <div class="flex-grow"><span data-dz-name></span></div>
                    <div class="flex-none w-24 text-right"><span data-dz-size></span></div>
                </div>
            </div>
        `;

        const dropzoneOptions = computed(() => {
            return {
                url: 'https://httpbin.org/post',
                method: 'post',
                thumbnailWidth: 150,
                thumbnailHeight: 150,
                addRemoveLinks: false,
                maxFiles: props.multiple ? null : 1, // Doesn't change if you choose "other".
                uploadMultiple: false,
                maxFilesize: null,
                autoProcessQueue: false,
                autoQueue: true,
                createImageThumbnails: false,
                parallelUploads: 2,
                params: {},
                previewTemplate: template(),
            };
        });

        const uploadFiles = (url: any, params: any) => {
            if (!vuedropzone.value) return;
            const files = vuedropzone.value.getAcceptedFiles();
            vuedropzone.value.setOption('url', url);
            vuedropzone.value.setOption('params', params);
            vuedropzone.value.setOption('parallelUploads', files.length);
            vuedropzone.value.processQueue();
        };

        const removeAllFiles = () => {
            if (!vuedropzone.value) return;
            vuedropzone.value.removeAllFiles();
        };

        const removeFile = (file: File) => {
            if (!vuedropzone.value) return;
            vuedropzone.value.removeFile(file);
        };

        const onMaxFilesExceeded = (file: File) => {
            if (!vuedropzone.value) return;
            emit('max-files-exceeded');
            vuedropzone.value.removeFile(file);
        };

        const onFilesAdded = () => {
            if (!vuedropzone.value) return;
            // Concatenating the already accepted files and the newly added file before sending them via event
            const files = vuedropzone.value.getAcceptedFiles().concat(vuedropzone.value.getAddedFiles());
            const uniqueFiles = files.reduce((acc: File[], file: File) => {
                if (!props.uploadedFiles.includes(file.name)) acc.push(file);
                else removeFile(file);
                return acc;
            }, []);
            emit('has-duplicate-file', uniqueFiles.length !== files.length);
            emit('files-added', uniqueFiles);
        };

        const addFile = (file: File) => {
            if (!vuedropzone.value) return;
            vuedropzone.value.addFile(file);
        };

        return {
            filesText,
            dropzoneOptions,
            vuedropzone,
            uploadFiles,
            removeAllFiles,
            removeFile,
            onMaxFilesExceeded,
            onFilesAdded,
            addFile,
        };
    },
});
