















































































































import { DropdownMenu } from '@/app/components';
import { useFilters } from '@/app/composable';
import {
    ArchiveIcon,
    CalendarIcon,
    CollectionIcon,
    CreditCardIcon,
    DotsVerticalIcon,
    LockClosedIcon,
} from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Status } from '../constants';

dayjs.extend(utc);

export default defineComponent({
    name: 'ModelOverview',
    props: {
        model: {
            type: Object,
            required: true,
        },
        strikeThroughDeprecated: {
            type: Boolean,
            default: true,
        },
        admin: {
            type: Boolean,
            default: false,
        },
        metadata: {
            type: Object as PropType<{ concepts: number; fields: number }>,
            default: () => {},
        },
    },
    components: {
        DropdownMenu,
        DotsVerticalIcon,
        LockClosedIcon,
        CreditCardIcon,
        CollectionIcon,
        CalendarIcon,
        ArchiveIcon,
    },
    setup(props: any, { emit }: { emit: any }) {
        const { formatDateTime } = useFilters();

        const options = ref<any[]>([]);

        const isDraft = computed(() => props.model.status === Status.Draft);
        const isStable = computed(
            () => props.model.status === Status.Stable && props.model.majorVersion === props.model.domainMajorVersion,
        );
        const inReview = computed(() => props.model.status === Status.UnderRevision);
        const isUnderRevision = computed(
            () => props.model.status === Status.Stable && props.model.majorVersion < props.model.domainMajorVersion,
        );
        const isDeprecated = computed(() => props.model.status === Status.Deprecated);

        const commonOptions = [
            {
                name: 'Edit',
                action: () => {
                    emit('edit', props.model);
                },
            },
            {
                name: 'Publish/Release',
                requiresConfirm: true,
                once: true,
                action: () => {
                    emit('publish', props.model);
                },
            },
        ];

        if (isStable.value) {
            options.value.push(
                {
                    name: 'Edit',
                    action: () => {
                        emit('edit', props.model);
                    },
                },
                {
                    name: 'Clone',
                    requiresConfirm: true,
                    action: () => {
                        emit('clone', props.model);
                    },
                },
                {
                    name: 'Deprecate',
                    requiresConfirm: true,
                    once: true,
                    action: () => {
                        emit('delete', props.model, 'deprecate');
                    },
                },
            );
            if (props.admin) {
                options.value.push({
                    name: 'Export',
                    action: () => {
                        emit('export', props.model);
                    },
                });
            }
        } else if (isDraft.value) {
            options.value.push(
                ...commonOptions,
                {
                    name: 'Clone',
                    requiresConfirm: true,
                    action: () => {
                        emit('clone', props.model);
                    },
                },
                {
                    name: 'Delete',
                    requiresConfirm: true,
                    once: true,
                    action: () => {
                        emit('delete', props.model, 'delete');
                    },
                },
            );
        } else if (inReview.value) {
            options.value.push(
                ...commonOptions,
                {
                    name: 'Clone',
                    requiresConfirm: true,
                    action: () => {
                        emit('clone', props.model);
                    },
                },
                {
                    name: 'Cancel Revision',
                    requiresConfirm: true,
                    action: () => {
                        emit('delete', props.model, 'delete');
                    },
                },
            );
        }

        return {
            dayjs,
            inReview,
            isDeprecated,
            isDraft,
            isStable,
            isUnderRevision,
            options,
            Status,
            formatDateTime,
        };
    },
});
